<template>
  <div :class="['location', 'page-content']">
    <breadcrumb title="Locations" bgImage="/assets/bg/locations.jpg" />

    <div class="filter d-flex justify-content-between d-lg-none">
      <div class="btns-group">
        <button :class="['btn btn-sm active']">Liste</button>
        <button @click="$router.push('/locations/karte')" :class="['btn btn-sm', !liste ? 'active' : '']">Karte</button>
      </div>

      <button
      class="btn btn-simple btn-icon"
      @click="filterDropdown = !filterDropdown">
      Filter
      <span class="material-icons">{{ filterDropdown ? 'expand_less' : 'expand_more' }}</span>
    </button>
  </div>
  <div v-if="filterDropdown" class="filter-dropdown d-lg-none">
    <filter-options :dropdown="true" />
  </div>

  <div class="container my-4 my-lg-5">
    <div class="row">
      <div class="col-12 text-center mb-3 d-none d-lg-block">
        <h3>Bitte wähle zuerst eine Region als Reiseziel.</h3>
      </div>

      <div v-if="false" class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
        <form>
          <destination-select @changedSelectedDestination="destinationChanged"/>
        </form>
      </div>

      <div class="col-12">
        <h3 class="section-title mb-0">Wie wäre es mit...</h3>
        <card-slider :data="destinations" card="destination" @cardClicked="destinationChanged"/>
      </div>
    </div>
  </div>
  <!-- Footer Margin -->
  <div class="footer-margin"></div>
</div>
</template>

<script>

  import { isMobile } from 'mobile-device-detect';

  export default {
    name: 'Locations',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
      FilterOptions: () => import('@/components/FilterOptions'),
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
      CardSlider: () => import('@/components/CardSlider')
    },
    data: () => ({
      liste: true,
      filterDropdown: false,
      destination: "",
      isItMobile: isMobile ? true : false,
    }),
    computed:{
      target(){
        return this.$route.params.target;
      },
      destinations(){
        return this.$store.getters.getDestinations;
      },
      storedDestination(){
        return this.$store.getters.getSelectedDestination;
      },
    },
    created() {
      this.redirect();

      /*check if there is a destination set in the store*/
      if(this.storedDestination!=null && this.storedDestination!=""){
        this.destination = this.storedDestination;
        /*go directly to the next view*/
        this.redirect();
      }
    },
    methods: {
      destinationChanged(destination=null){
        if(destination!=null){
          this.destination=destination;
        }
        this.$emit('destinationChanged',this.destination);
        /*make sure that no categories are set*/
        this.$store.commit('SET_SELECTED_CATEGORIES', []);
        this.$store.commit('SET_SELECTED_DESTINATION',this.destination);
        this.redirect();
      },
      redirect(){
        if(this.target == "events"){
          this.$router.push({ name: 'EventIndex', params: {destination: encodeURIComponent(this.destination.name) } });
        }
        else if(this.target == "locations"){

          if(this.isItMobile){
            /*if we're on a mobile phone, we'll just continue to the Region-overview*/
            this.$router.push({ name: 'LocationIndex', params: {destination: encodeURIComponent(this.destination.name) } });
          }
          else{
            /*if we're not on a mobile phone, we'll continue to the map-view*/
            this.$router.push({ name: 'LocationKarte', params: {destination: encodeURIComponent(this.destination.name) } });
          }

        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
</style>